import React from "react";

function article_1() {
	return {
		title: "Manfaat AI di Tahun 2024: Apa yang Harus Anda Ketahui?",
		description:
			"Kecerdasan Buatan (AI) memberikan dampak besar di berbagai industri pada tahun 2024. Temukan bagaimana AI meningkatkan efisiensi, produktivitas, dan menciptakan peluang baru di dunia kerja dan kehidupan sehari-hari.",
		keywords: [
			"Manfaat AI di 2024",
			"Kecerdasan Buatan di Industri",
			"AI untuk Efisiensi dan Produktivitas",
			"Peluang Baru dengan AI",
			"Dampak AI pada Bisnis",
			"Evan Luis Tanjung",
			"Evan Luis",
			"VanZPro",
		],
		style: `
	.article-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;
		font-family: Arial, sans-serif;
		color: #333;
		line-height: 1.6;
		max-width: 800px; /* Batas lebar maksimum untuk konten */
		margin: 0 auto; /* Agar konten berada di tengah */
	}

	.article-title {
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 20px;
		text-align: center;
	}

	.article-date {
		color: #888;
		font-size: 14px;
		margin-bottom: 20px;
		text-align: center;
	}

	.paragraph {
		margin-bottom: 15px;
		text-align: justify;
	}

	.randImage {
		margin-top: 20px;
		max-width: 100%;
		border-radius: 10px;
		outline: 2px solid red;
	}

	/* Responsif untuk layar kecil */
	@media (max-width: 600px) {
		.article-title {
			font-size: 20px;
			margin-bottom: 15px;
		}

		.article-date {
			font-size: 12px;
			margin-bottom: 15px;
		}

		.paragraph {
			font-size: 14px;
			padding: 0 10px;
		}

		.randImage {
			margin-top: 15px;
			width: 100%;
			outline: 1px solid red;
		}
	}
`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="article-title">Berikut Adalah Manfaat AI Yang Belum Kamu Ketahui</div>
					<div className="article-date">11 November 2024</div>
					
					<div className="paragraph">
						Pada tahun 2024, kecerdasan buatan atau AI terus berkembang pesat dan berdampak signifikan di berbagai sektor, dari kesehatan, pendidikan, hingga bisnis. Dengan kemampuannya untuk mengotomatisasi tugas-tugas kompleks dan menganalisis data secara mendalam, AI menjadi alat yang sangat penting dalam meningkatkan produktivitas dan efisiensi.
					</div>

					<div className="paragraph">
						Sebagai contoh, di bidang kesehatan, AI digunakan untuk mendiagnosis penyakit dengan akurasi yang lebih tinggi, membantu dokter merancang rencana perawatan yang lebih baik, dan bahkan memprediksi wabah penyakit. Di sektor bisnis, AI membantu perusahaan dalam analisis data, manajemen rantai pasokan, dan memahami kebutuhan pelanggan dengan lebih baik, sehingga mereka dapat menawarkan layanan yang lebih personal.
					</div>

					<div className="paragraph">
						Penggunaan AI dalam otomasi industri juga menghadirkan perubahan signifikan, terutama dalam hal meminimalkan kesalahan manusia dan meningkatkan produksi dengan biaya yang lebih rendah. Bagi para pekerja, AI menciptakan peluang baru di pekerjaan yang membutuhkan keterampilan analitis dan teknologi, memungkinkan mereka untuk fokus pada tugas-tugas yang lebih strategis.
					</div>

					<div className="paragraph">
						Namun, meski AI menawarkan banyak manfaat, tantangan tetap ada, terutama terkait privasi, keamanan data, dan dampaknya terhadap lapangan kerja. Oleh karena itu, penting bagi organisasi untuk mengadopsi AI secara etis dan transparan serta berinvestasi dalam pelatihan tenaga kerja agar dapat beradaptasi dengan perkembangan teknologi ini.
					</div>

					<img
						src="https://incubator.ucf.edu/wp-content/uploads/2023/07/artificial-intelligence-new-technology-science-futuristic-abstract-human-brain-ai-technology-cpu-central-processor-unit-chipset-big-data-machine-learning-cyber-mind-domination-generative-ai-scaled-1-2048x1366.jpg"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}
function article_2() {
	return {
		title: "Perkembangan Teknologi Blockchain di Tahun 2024: Apa yang Harus Anda Ketahui?",
		description:
			"Blockchain terus mengalami perkembangan pesat pada tahun 2024, membawa inovasi di berbagai sektor seperti keuangan, kesehatan, dan rantai pasokan. Pelajari bagaimana teknologi ini mengubah cara kita bertransaksi dan menyimpan data.",
			keywords: [
				"Perkembangan Blockchain 2024",
				"Teknologi Blockchain di Keuangan",
				"Blockchain untuk Keamanan Data",
				"Blockchain di Industri Kesehatan",
				"Inovasi Blockchain",
				"Smart Contracts",
				"Cryptocurrency dan Blockchain",
			],
			style: `
		.article-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 20px;
			font-family: Arial, sans-serif;
			color: #333;
			line-height: 1.6;
			max-width: 800px;
			margin: 0 auto;
		}
	
		.article-title {
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 20px;
			text-align: center;
		}
	
		.article-date {
			color: #888;
			font-size: 14px;
			margin-bottom: 20px;
			text-align: center;
		}
	
		.paragraph {
			margin-bottom: 15px;
			text-align: justify;
		}
	
		.randImage {
			margin-top: 20px;
			max-width: 100%;
			border-radius: 10px;
			outline: 2px solid red;
		}
	
		@media (max-width: 600px) {
			.article-title {
				font-size: 20px;
				margin-bottom: 15px;
			}
	
			.article-date {
				font-size: 12px;
				margin-bottom: 15px;
			}
	
			.paragraph {
				font-size: 14px;
				padding: 0 10px;
			}
	
			.randImage {
				margin-top: 15px;
				width: 100%;
				outline: 1px solid red;
			}
		}
		`,
			body: (
				<React.Fragment>
					<div className="article-content">
						<div className="article-title">Blockchain di Tahun 2024: Revolusi Teknologi yang Mengubah Dunia</div>
						<div className="article-date">11 November 2024</div>
	
						<div className="paragraph">
							Tahun 2024 menjadi tahun yang penuh inovasi bagi teknologi blockchain. Tidak hanya digunakan dalam dunia cryptocurrency, blockchain kini merambah ke berbagai sektor penting seperti keuangan, kesehatan, dan rantai pasokan, membawa dampak positif yang luar biasa. Teknologi ini memungkinkan transaksi yang lebih aman, transparan, dan efisien, mengubah cara kita berinteraksi dengan data.
						</div>
	
						<div className="paragraph">
							Salah satu penerapan blockchain yang paling terkenal adalah dalam cryptocurrency, di mana teknologi ini memastikan setiap transaksi tercatat dengan aman dan transparan. Namun, blockchain juga mulai diterapkan dalam sektor keuangan lainnya, seperti pengelolaan aset digital dan pembayaran lintas negara yang lebih cepat dan murah.
						</div>
	
						<div className="paragraph">
							Di bidang kesehatan, blockchain digunakan untuk menyimpan rekam medis pasien dengan tingkat keamanan yang sangat tinggi. Hal ini memungkinkan dokter dan penyedia layanan kesehatan untuk mengakses data dengan mudah, tanpa khawatir tentang potensi pelanggaran privasi. Selain itu, blockchain juga digunakan untuk memastikan transparansi dalam pengadaan obat dan perangkat medis.
						</div>
	
						<div className="paragraph">
							Blockchain juga membawa perubahan besar dalam rantai pasokan. Dengan menggunakan teknologi ini, perusahaan dapat melacak setiap langkah dari barang yang diproduksi hingga sampai ke konsumen, menjamin keaslian produk dan mengurangi risiko penipuan.
						</div>
	
						<img
							src="https://news.tokocrypto.com/wp-content/uploads/2022/03/apa-itu-blockchain.png"
							alt="random"
							className="randImage"
						/>
					</div>
				</React.Fragment>
			),
		};
}

const myArticles = [article_1, article_2];

export default myArticles;
