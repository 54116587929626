const INFO = {
	main: {
		title: "Vanzcode",
		name: "Evan Luis T.",
		email: "evanluis198@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/VanZPro",
		instagram: "https://instagram.com/evanluis_t",
		facebook: "https://www.facebook.com/EvanLuisTanjung1",
		youtube: "https://www.youtube.com/@Evanluistanjung.198"
	},

	homepage: {
		title: "Evan Luis Tanjung",
		description:
			"Saya adalah siswa SMK NEGERI 1 PANYINGKIRAN, seorang siswa yang menekuni bidang Rekayasa Perangkat Lunak dengan fokus pada pengembangan back-end. Saya memiliki tekad yang kuat untuk terus belajar dan mengasah keterampilan saya demi mencapai kesempurnaan.Selalu berusaha untuk menemukan solusi terbaik dalam setiap proyek yang saya kerjakan dan memiliki minat besar di bidang pengembangan back-end..",
	},

	articles: {
		title: "Artikel",
		description:
			"News",
	},

	projects: [
		{
			title: "Absensi PKL",
			description:
				"Absensi PKL merupakan project pada saat PKL di BBPPMVP BMTI, merupakan sebuah aplikasi absensi berbasis lokasi.",
			logo: "https://i.ibb.co.com/p0JP3bN/Screenshot-2024-11-12-100036.png",
			linkText: "Demo",
			link: "https://github.com",
		},
	],
};

export default INFO;
