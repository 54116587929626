import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "contact");

	// State untuk menyimpan input form
	const [formData, setFormData] = useState({
		nama: "",
		email: "",
		subject: "",
		message: "",
	});

	// Fungsi untuk menangani perubahan input
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	// Fungsi untuk menangani submit form
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Form Data:", formData);
		// Tambahkan logika pengiriman form di sini
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta name="keywords" content={currentSEO.keywords.join(", ")} />
			</Helmet>

			<div className="page-content">
				<NavBar active="contact" />
				<div className="content-wrapper">
					<div className="contact-logo-container">
						<div className="contact-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="contact-container">
						<div className="title contact-title">
						Cara Terhubung dengan Saya
						</div>

						<div className="subtitle contact-subtitle">
							Terima kasih atas minat Anda untuk menghubungi Saya. Saya menyambut masukan, pertanyaan, dan saran. Jika Anda memiliki pertanyaan spesifik atau komentar, jangan ragu untuk mengirim email kepada saya langsung di&nbsp;
							<a href={`mailto:${INFO.main.email}`}>{INFO.main.email}</a>. Saya berusaha untuk menanggapi semua pesan dalam 24 jam, meskipun mungkin memerlukan waktu lebih lama saat saya sibuk. Alternatifnya, Anda dapat menggunakan formulir kontak di situs web saya untuk menghubungi. Cukup isi bidang yang wajib diisi dan saya akan menghubungi Anda sesegera mungkin. Terakhir, jika Anda lebih suka terhubung melalui media sosial, Anda dapat menemukan saya di&nbsp;
							<a href={INFO.socials.instagram} target="_blank" rel="noreferrer">{INFO.socials.instagram}</a>.
						</div>
					</div>
						<div className="contact-socials">
							<Socials />
						</div>
					</div>

					<div className="page-footer">
						<Footer />
					</div>
				</div>
		</React.Fragment>
	);
};

export default Contact;
