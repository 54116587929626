import React from "react";
import { faSchool } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/schools.css";

const Schools = () => {
	return (
		<div className="schools">
			<Card
				icon={faSchool}
				title="Education"
				body={
					<div className="schools-body">
						<div className="school">
							<img
								src="./panyingkiran.png"
								alt="nesapa"
								className="school-image"
							/>
							<div className="school-title">SMK Negeri 1 Panyingkiran</div>
							<div className="school-subtitle">
							2022 - 2025
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Schools;
